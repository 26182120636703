<template>
  <div class="securityReinforcementAlls">
    <div class="securityReinforcementFirst">
      <div class="securityReinforcementFirst1">
        <h1>信息安全加固服务</h1>
        <p>
          安全加固服务，是指根据安全加固列表，对目标系统的安全漏洞对进行修复、配置隐患进行优化的过程。加固内容包括但不限于系统补丁、防火墙、防病毒、危险服务、共享、自动播放、密码安全。
        </p>
      </div>
    </div>
    <div class="securityReinforcementSecond">
      <h1>安全加固内容</h1>
      <div class="securityReinforcementSecond1">
        <ul>
          <li>
            <div class="securityReinforcementSecond1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="securityReinforcementSecond1-2">
              <p class="securityReinforcementSecond1-2-1">数据库安全加固</p>
              <span class="securityReinforcementSecond1-2-2">
                进行安全加固的数据库系统包括Oracle、SQL Server、DB2
              </span>
            </div>
          </li>
          <li>
            <div class="securityReinforcementSecond1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="securityReinforcementSecond1-2">
              <p class="securityReinforcementSecond1-2-1">中间件安全加固</p>
              <span class="securityReinforcementSecond1-2-2">
                御之安科技进行安全加固的中间件系统包括Tomcat、Apache、WebLogic、WebSphere
              </span>
            </div>
          </li>
          <li>
            <div class="securityReinforcementSecond1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="securityReinforcementSecond1-2">
              <p class="securityReinforcementSecond1-2-1">操作系统加固内容</p>
              <span class="securityReinforcementSecond1-2-2">
                进行安全加固的操作系统包括Windows、Linux、AIX、HP-Unix、Solaris。
              </span>
            </div>
          </li>
          <li>
            <div class="securityReinforcementSecond1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="securityReinforcementSecond1-2">
              <p class="securityReinforcementSecond1-2-1">安全设备安全加固</p>
              <span class="securityReinforcementSecond1-2-2">
                通过早期代码安全测试可以捕获大多数漏洞并及时被消除，减少因潜在风险导致的意外延迟，也避免在业务上造成重大损失。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="securityReinforcementThird">
      <h2>安全服务内容</h2>
      <div class="securityReinforcementThird1">
        <img
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fn.sinaimg.cn%2Fsinakd2021525s%2F215%2Fw1080h735%2F20210525%2Fb10f-kqpyffz3690618.png&refer=http%3A%2F%2Fn.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672394418&t=efa34470542f43f6d138e5a1df681ffe"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .securityReinforcementAlls {
    width: 100%;
    .securityReinforcementFirst {
      background-image: url(../../../../assets/img/sofaFW/4.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 100px 40px;
      display: flex;
      justify-content: flex-start;
      .securityReinforcementFirst1 {
        h1 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 30px;
          text-align: center;
          color: #fff;
        }
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          color: #fff;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }
    .securityReinforcementSecond {
      width: 100%;
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .codeAuditThird11 {
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li {
          display: flex;
          padding: 20px;
          background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
          border-radius: 12px;
          margin-top: 20px;
          .codeAuditThird1-1 {
            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }
          }
          .codeAuditThird1-2 {
            .codeAuditThird1-2-1 {
              font-size: 24px;
              color: #1f2329;
            }
            .codeAuditThird1-2-2 {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
    .securityReinforcementThird {
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px;
      h2 {
        text-align: center;
        margin: 20px 0;
      }
      .securityReinforcementThird1 {
        padding: 30px;
        display: flex;
        justify-content: center;
        img {
          width: 80vw;
          height: 33vh;
          // box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
        }
      }
    }
  }
}
</style>